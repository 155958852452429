import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import GlobalStyle from '../assets/styles/GlobalStyle';
import { COLORS } from '../assets/styles/variables';
import Button from '../atoms/Button';
import Spacer from '../atoms/Spacer';
import Text from '../atoms/Text';
import { AGRONOMIST_CARDS } from '../config/cardsContents';
import { ENTITIES, URLS } from '../config/constants';
import Layout, { MyContext } from '../layout/LayoutContainer';
import '../locales/i18n';
import ScrollBottomButton from '../molecules/ScrollBottomButton';
import CTABanner from '../organisms/CTABanner/CTABanner';
import CTABannerMainText from '../organisms/CTABanner/CTABannerMainText';
import CTABannerSubText from '../organisms/CTABanner/CTABannerSubText';
import Footer, { MobileFooter } from '../organisms/Footer/Footer';
import Header from '../organisms/Header';
//import OpinionsSection from '../organisms/OpinionsSection/OpinionsSection';
import QuoteSection from '../organisms/QuoteSection/QuoteSection';
import SectionHeader from '../organisms/SectionHeader/SectionHeader';
import SectionHeaderMainText from '../organisms/SectionHeader/SectionHeaderMainText';
//import SectionHeaderSubText from '../organisms/SectionHeader/SectionHeaderSubText';
import ShowcaseCard from '../organisms/ShowcaseCard/ShowcaseCard';

const currentEntity = ENTITIES.agronomist;

class AgronomistPage extends React.Component {
  onLogin = () => window.open(URLS.login, '_BLANK');
  onPlatformSignup = () => window.open(URLS.signup + '?areaOfInterest=farmer', '_BLANK');
  //  onSignup = () =>
  //    navigate(NAV_ITEMS.contactUs.linkTo, {
  //      state: { persona: currentEntity },
  //    });

  render() {
    const { t } = this.props;

    const HeaderCon = (props) => {
      return <MyContext.Consumer>{(value) => <Header {...value} {...props} />}</MyContext.Consumer>;
    };

    return (
      <Layout {...this.props}>
        <HeaderCon loginCb={this.onLogin} />

        <CTABanner height={640} bgImage={`${currentEntity}/${currentEntity}.png`} bgPosition={'center'}>
          <div>
            <CTABannerMainText block>{t(`app:${currentEntity}:cta:title`)}</CTABannerMainText>
            <CTABannerSubText block>
              <Trans i18nKey={`app:${currentEntity}:cta:subtitle`}>
                <Text weight={'bold'} />
                <Text weight={'bold'} />
                <Text weight={'bold'} />
              </Trans>
            </CTABannerSubText>
            <Spacer height={'30px'} />
            <Button big onClick={this.onPlatformSignup}>
              {t('app:signup_btn')}
            </Button>
            <ScrollBottomButton section={'#who-are-you'} />
          </div>
        </CTABanner>

        <SectionHeader>
          <SectionHeaderMainText>{t(`app:${currentEntity}:who_are_you`)}</SectionHeaderMainText>
        </SectionHeader>
        <QuoteSection
          topDescription={t(`app:${currentEntity}:difficulties_desc`)}
          quoteText={t(`app:${currentEntity}:quote`)}
          bottomDescription={t(`app:${currentEntity}:what_they_need`)}
        />

        <section id="who-are-you">
          <div>
            <SectionHeader bgColor={COLORS.sectionBg}>
              <SectionHeaderMainText>{t(`app:${currentEntity}:divider_title`)}</SectionHeaderMainText>
            </SectionHeader>
          </div>
        </section>

        {AGRONOMIST_CARDS.map((item, i) => (
          <ShowcaseCard
            key={i}
            reverse={!!(i % 2)}
            image={`${currentEntity}/${item.image}`}
            title={t(`app:${currentEntity}:cards:title${i + 1}`)}
            description={t(`app:${currentEntity}:cards:description${i + 1}`)}
          />
        ))}

        {/*<OpinionsSection category={currentEntity} signupCb={this.onSignup} />*/}
        <SectionHeader bgColor={COLORS.sectionBg}>
          <Button big onClick={this.onPlatformSignup}>
            {t('app:signup_btn')}
          </Button>
        </SectionHeader>
        <Footer />
        <MobileFooter />
        <GlobalStyle />
      </Layout>
    );
  }
}

AgronomistPage.propTypes = {};

export default withTranslation()(AgronomistPage);
